
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import CustomTableFilters from '@/components/tableWithActions/CustomTableFilters.vue';
import { iterable } from '@/utils/array';

const MINUTES_PER_DAY = 60 * 24;

@Component({
  components: {
    CustomTableFilters,
  },
})
export default class GanttChartFilters extends Vue {
  @Prop({ required: true })
  private defaultZoom!: [Date, Date];

  @Prop({ required: true })
  private states!: string[];

  @Prop({ required: true })
  private getStateColor!: (state: string) => string;

  private static readonly DEFAULT_SLIDER_START_VALUE = 0;

  private selectedStates: string[] = [];
  private zoomValue: [number, number] = [
    GanttChartFilters.DEFAULT_SLIDER_START_VALUE,
    GanttChartFilters.DEFAULT_SLIDER_START_VALUE,
  ];

  private startTimestamp: moment.Moment = moment.utc().subtract(24, 'hours');

  private mounted() {
    this.selectedStates = this.states;
    this.initializeTimestamps();
    this.initializeValue();
  }

  @Watch('defaultZoom')
  private watchDefaultZoom() {
    this.initializeTimestamps();
    this.initializeValue();

    // The following code handles the case when the table is refreshed (and thus the defaultZoom changed)
    // and we want to update the filter state.
    // If only the zoom was being used, and now that the defaultZoom was reset,
    // no filter is being used and the table must be notified (by sending areApplying to false)
    const areApplying = this.selectedStates.length !== this.states.length;
    this.$emit('filters-change', this.selectedStates, this.defaultZoom, areApplying);
  }

  private initializeTimestamps() {
    this.startTimestamp = moment(this.defaultZoom[0]);
  }

  private initializeValue() {
    this.zoomValue = [GanttChartFilters.DEFAULT_SLIDER_START_VALUE, this.totalMinutes];
  }

  private toggleState(state: string) {
    if (this.isStateDisabled(state)) {
      this.selectedStates = [...this.selectedStates, state];
    } else {
      this.selectedStates = this.selectedStates.filter((x) => x !== state);
    }
  }

  private isStateDisabled(state: string): boolean {
    return !this.selectedStates.includes(state);
  }

  private get totalMinutes(): number {
    return moment(this.defaultZoom[1]).diff(this.defaultZoom[0], 'minutes');
  }

  private get sliderTicks(): number[] {
    // For purely visual reasons, if it's already past 10 or later, the first tick
    // will be two hours later sharp. This is, at 9:10 the first marker is 11.

    const minutesToOClock =
      60 - this.startTimestamp.minutes() + (this.startTimestamp.minutes() >= 10 ? 60 : 0);
    const tickAmount = 8;

    // Here, we use MINUTES_PER_DAY instead of totalMinutes so that all hours are rounded up.
    // totalMinutes will usually be MINUTES_PER_DAY + 5, so the ticks can be a minute or two off,
    // which doesn't look as pretty.
    return iterable(tickAmount).map((x) => minutesToOClock + (x * MINUTES_PER_DAY) / tickAmount);
  }

  private formatZoomValue(value: number): string {
    return moment(this.startTimestamp).local().add(value, 'minutes').format('HH:mm');
  }

  private emitFilters() {
    const zoomValues = [
      moment(this.startTimestamp).add(this.zoomValue[0], 'minutes').toDate(),
      moment(this.startTimestamp).add(this.zoomValue[1], 'minutes').toDate(),
    ];
    const areApplying = !(
      this.selectedStates.length === this.states.length &&
      this.zoomValue[0] === GanttChartFilters.DEFAULT_SLIDER_START_VALUE &&
      this.zoomValue[1] === this.totalMinutes
    );
    this.$emit('filters-change', this.selectedStates, zoomValues, areApplying);
  }

  private clearAllFilters() {
    this.selectedStates = this.states;
    this.zoomValue = [GanttChartFilters.DEFAULT_SLIDER_START_VALUE, this.totalMinutes];
    this.emitFilters();
  }
}
