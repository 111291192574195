// --------------------------------------------------------------------------------
// <copyright file="gantt.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { GanttTableData, GroupedGanttTableData } from '@/models/Charts/chartsData';
import moment from 'moment';
import { formatDuration } from './dates';

export function getTotalLengthOfState(
  deviceid: string,
  state: string,
  data: GanttTableData<any>[],
  zoomValues: [Date, Date],
) {
  const countableData = data.filter((x: any) => x.state === state && x.deviceid === deviceid);

  const inSeconds = (date: Date) => date.getTime() / 1000;

  const sum = (list: number[]) => list.reduce((acc, x) => acc + x, 0);

  const nonSplitIntervalSeconds = sum(
    countableData
      .filter(
        (x: any) =>
          x.startTimestamp >= inSeconds(zoomValues[0]) &&
          x.endTimestamp <= inSeconds(zoomValues[1]),
      )
      .map((x: any) => x.endTimestamp - x.startTimestamp),
  );

  const startSplit = sum(
    countableData
      .filter(
        (x: any) =>
          x.startTimestamp < inSeconds(zoomValues[0]) && x.endTimestamp > inSeconds(zoomValues[0]),
      )
      .map((x: any) => x.endTimestamp - inSeconds(zoomValues[0])),
  );
  const endSplit = sum(
    countableData
      .filter(
        (x: any) =>
          x.startTimestamp < inSeconds(zoomValues[1]) && x.endTimestamp > inSeconds(zoomValues[1]),
      )
      .map((x: any) => inSeconds(zoomValues[1]) - x.startTimestamp),
  );

  const lengthInSeconds = startSplit + endSplit + nonSplitIntervalSeconds;
  return formatDuration(lengthInSeconds);
}

export function getDefaultZoomValues(): [Date, Date] {
  const endMoment = moment.utc().seconds(0);
  if (endMoment.minutes() % 5 !== 0) {
    endMoment.add(5 - (endMoment.minutes() % 5), 'minutes');
  }
  const startMoment = moment.utc().seconds(0).subtract(24, 'hours');
  startMoment.subtract(startMoment.minutes() % 5, 'minutes');
  return [startMoment.toDate(), endMoment.toDate()];
}

export function dataGroupedByDevice(
  data: GanttTableData<any>[] | null,
  zoomValues: [Date, Date],
  deviceIds: string[],
): GroupedGanttTableData<any>[] {
  if (!data) return [];
  return deviceIds.map((deviceid) => ({
    deviceid,
    ganttData: data
      .filter((x) => x.deviceid === deviceid)
      .filter(
        (x) =>
          moment(x.endTimestamp * 1000).isSameOrAfter(zoomValues[0]) &&
          moment(x.startTimestamp * 1000).isSameOrBefore(zoomValues[1]),
      ),
  }));
}

export function getLastState(row: GroupedGanttTableData<any>) {
  const maxStartTimestamp = row.ganttData
    .map((x) => x.startTimestamp)
    .reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE);
  return row.ganttData.find((x) => x.startTimestamp === maxStartTimestamp)?.state ?? '';
}

// The method filters the data to retain only entries whose state is selected and
// making sure the start timestamp is not repeated.
export function filterDeviceDataByStates(
  data: GanttTableData<any>[],
  states: string[],
): GanttTableData<any>[] {
  return Object.values(
    data
      .filter((x) => states.includes(x.state))
      .reduce((ret, obj) => {
        if (obj.startTimestamp in ret) {
          return ret;
        }
        ret[obj.startTimestamp] = obj;
        return ret;
      }, Object.create(null)),
  ) as GanttTableData<any>[];
}
